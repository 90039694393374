@font-face {
  font-family: "Gilroy";
  font-weight: 100;
  src: url("../fonts/Gilroy-Thin.otf") format("opentype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 100;
  src: url("../fonts/Gilroy-ThinItalic.otf") format("opentype");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 200;
  src: url("../fonts/Gilroy-UltraLight.otf") format("opentype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 200;
  src: url("../fonts/Gilroy-UltraLightItalic.otf") format("opentype");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 300;
  src: url("../fonts/Gilroy-Light.otf") format("opentype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 300;
  src: url("../fonts/Gilroy-LightItalic.otf") format("opentype");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  src: url("../fonts/Gilroy-Regular.otf") format("opentype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 400;
  src: url("../fonts/Gilroy-RegularItalic.otf") format("opentype");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  src: url("../fonts/Gilroy-Medium.otf") format("opentype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 500;
  src: url("../fonts/Gilroy-MediumItalic.otf") format("opentype");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  src: url("../fonts/Gilroy-SemiBold.otf") format("opentype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 600;
  src: url("../fonts/Gilroy-SemiBoldItalic.otf") format("opentype");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 700;
  src: url("../fonts/Gilroy-Bold.otf") format("opentype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 700;
  src: url("../fonts/Gilroy-BoldItalic.otf") format("opentype");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 800;
  src: url("../fonts/Gilroy-ExtraBold.otf") format("opentype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 800;
  src: url("../fonts/Gilroy-ExtraBoldItalic.otf") format("opentype");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 900;
  src: url("../fonts/Gilroy-Black.otf") format("opentype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  font-weight: 900;
  src: url("../fonts/Gilroy-BlackItalic.otf") format("opentype");
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?wjf4kf');
  src: url('../fonts/icomoon.eot?wjf4kf#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?wjf4kf') format('truetype'),
    url('../fonts/icomoon.woff?wjf4kf') format('woff'),
    url('../fonts/icomoon.svg?wjf4kf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checklist:before {
  content: "\e945";
}

.icon-phone:before {
  content: "\e944";
}

.icon-calendar-detailed:before {
  content: "\e93e";
}

.icon-flash:before {
  content: "\e93f";
}

.icon-material-isolation:before {
  content: "\e940";
}

.icon-messages-people-man-bubble-oval:before {
  content: "\e941";
}

.icon-mood-rock:before {
  content: "\e942";
}

.icon-presentation-projector-screen-programing:before {
  content: "\e943";
}

.icon-alert-diamond:before {
  content: "\e93d";
}

.icon-smiley-indifferent:before {
  content: "\e93a";
}

.icon-smiley-sad:before {
  content: "\e93b";
}

.icon-smiley-smile:before {
  content: "\e93c";
}

.icon-gauge-dashboard:before {
  content: "\e937";
}

.icon-star-circle:before {
  content: "\e938";
}

.icon-surveillance-target:before {
  content: "\e939";
}

.icon-cog:before {
  content: "\e936";
}

.icon-book:before {
  content: "\e934";
}

.icon-e-learning-laptop:before {
  content: "\e935";
}

.icon-navigation-left:before {
  content: "\e933";
}

.icon-chevron-down:before {
  content: "\e907";
}

.icon-chevron-right:before {
  content: "\e932";
}

.icon-react:before {
  content: "\e925";
}

.icon-lock-circle:before {
  content: "\e931";
}

.icon-layers:before {
  content: "\e930";
}

.icon-check:before {
  content: "\e92f";
}

.icon-check-circle:before {
  content: "\e92c";
}

.icon-file-code:before {
  content: "\e92e";
}

.icon-rocket:before {
  content: "\e92d";
}

.icon-introduction-to-programming:before {
  content: "\e92b";
}

.icon-graduate:before {
  content: "\e92a";
}

.icon-box-open:before {
  content: "\e929";
}

.icon-kanban:before {
  content: "\e928";
}

.icon-team:before {
  content: "\e927";
}

.icon-requests:before {
  content: "\e926";
}

.icon-cloud:before {
  content: "\e924";
}

.icon-database:before {
  content: "\e923";
}

.icon-api:before {
  content: "\e922";
}

.icon-http:before {
  content: "\e921";
}

.icon-like-bubble:before {
  content: "\e920";
}

.icon-testing:before {
  content: "\e91f";
}

.icon-javascript:before {
  content: "\e91e";
}

.icon-webpage:before {
  content: "\e91c";
}

.icon-github:before {
  content: "\e91d";
}

.icon-laptop:before {
  content: "\e91a";
}

.icon-clock:before {
  content: "\e919";
}

.icon-one:before {
  content: "\e90f";
}

.icon-two:before {
  content: "\e910";
}

.icon-three:before {
  content: "\e911";
}

.icon-four:before {
  content: "\e912";
}

.icon-five:before {
  content: "\e913";
}

.icon-six:before {
  content: "\e914";
}

.icon-seven:before {
  content: "\e915";
}

.icon-eight:before {
  content: "\e916";
}

.icon-nine:before {
  content: "\e917";
}

.icon-ten:before {
  content: "\e918";
}

.icon-database-monitor-sync:before {
  content: "\e90b";
}

.icon-module-three:before {
  content: "\e90c";
}

.icon-programming-browser:before {
  content: "\e90d";
}

.icon-programming-code:before {
  content: "\e90e";
}

.icon-cross:before {
  content: "\e90a";
}

.icon-play:before {
  content: "\e909";
}

.icon-download:before {
  content: "\e908";
}

.icon-email:before {
  content: "\e901";
}

.icon-arrow-left:before {
  content: "\e91b";
}

.icon-arrow-right:before {
  content: "\e902";
}

.icon-close-quote:before {
  content: "\e903";
}

.icon-tag:before {
  content: "\e904";
}

.icon-hourglass:before {
  content: "\e905";
}

.icon-calendar:before {
  content: "\e906";
}

.icon-m-blue-side:before {
  content: "\e900";
}